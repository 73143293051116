import {
  Button,
  Container,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React, { FunctionComponent } from "react"
import MediaInfo from "../../components/mediaInfo"
import Navbar from "../../components/navbar"
import { getApiUrl } from "../../utils/functions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      maxWidth: 300,
      marginTop: theme.spacing(2),
    },
    imgLogo: {
      maxWidth: 150,
      marginTop: theme.spacing(3),
    },
    title: {
      color: theme.palette.primary.main,
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      color: "#C9AA7C",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    centrar: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    texto: {
      textAlign: "left",
    },
    footer: {
      textAlign: "center",
      backgroundColor: "#C9AA7C",
      width: "100%",
      padding: 5,
      marginTop: 20,
    },
    negritas: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
  })
)

const Proveedores: FunctionComponent = () => {
  const classes = useStyles()
  return (
    <>
      <MediaInfo />
      <Navbar />
      <Container style={{ position: "relative" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} className={classes.centrar}>
            <img
              src={require("../../assets/images/logo/logo-isset-negro.png")}
              className={classes.imgLogo}
            ></img>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.title}>
              Portal de Proveedores
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.subtitle}>
              ¿Qué es el portal de proveedores?
            </Typography>
            <Typography variant="body1">
              Es una plataforma en línea para proveedores registrados en el
              Instituto de Seguridad Social del Estado de Tabasco, en el cual,
              en esta primera versión, podrán consultar órdenes de compra
              (pedidos), facturas y pagos, que estén a nombre del proveedor que
              accede a la misma.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className={classes.subtitle}>
              ¿Qué necesitas para el acceso?
            </Typography>
            <Typography>
              <ul>
                <li className={classes.negritas}>RFC y contraseña. </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className={classes.subtitle}>
              ¿Quiénes tienen Acceso?
            </Typography>
            <Typography variant="body1">
              <ul>
                <li className={classes.negritas}>
                  Los Proveedores Registrados{" "}
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.centrar}>
            <Button
              className={classes.button}
              variant="contained"
              href={
                "https://entespublicos.isset.gob.mx:8443/issetPortalProveedores"
              }
            >
              ACCEDER
            </Button>
          </Grid>
          <Grid item xs={12} md={12} className={classes.centrar}>
            <Alert severity="info">
              Si aún no estás familiarizado con el nuevo sistema, puedes revisar
              el{" "}
              <Link
                target="_blank"
                className={classes.negritas}
                href={`${getApiUrl(
                  "portal"
                )}/static/docs/manuales/GuiadeUsuarioPortaldeProveedores.pdf`}
              >
                manual de usuario
              </Link>
            </Alert>
          </Grid>
          <Grid item xs={12} md={12} className={classes.centrar}>
            <Alert severity="warning" className={classes.texto}>
              Aclaraciones:
              <br /> Para Pedidos Teléfono: (993) 358 2850 Extensión 63063{" "}
              <br /> Facturación y pagos: (993) 358 2850 Extensión 63092
            </Alert>
          </Grid>
          <Grid item xs={12} md={12} className={classes.centrar}>
            <img
              className={classes.img}
              src={require("../../assets/images/secundary-pages/credito.svg")}
            />
          </Grid>
        </Grid>
      </Container>
      <footer className={classes.footer}>
        <Typography variant="subtitle2">
          Gobiernos del estado de Tabasco | Todos los derechos reservados - 2022
        </Typography>
      </footer>
    </>
  )
}

export default Proveedores
